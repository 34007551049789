import {useState, useRef} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import config from './config/config.js';
import ReCAPTCHA from "react-google-recaptcha";
import Alert from 'react-bootstrap/Alert';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [freeGuide, setFreeGuide] = useState('yes');
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const recaptchaRef = useRef(null);
  const SITE_KEY = '6Ld3A6wkAAAAANwty5dTaw95erZ5QvDiIDQfZlF2';

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);

    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const requestOptions = {
      method: 'POST',
      headers: {
        "Accept": "*/*",
        "Content-Type": "application/json;charset=UTF-8"
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        phone,
        password,
        freeGuide,
        captchaToken
      })
    };
    const response = await fetch(`${config.apiGateway}/freesignup`, requestOptions);
    console.log({response});
    const data = await response.json();
    console.log({data});
    if(data?.success){
      window.location.href = `https://toolchest.rentingauthority.com/?user=${email}&pass=${password}`;
      return;
    }
    console.log(data)
    setErrorMessage({
      message: 'reCaptcha error. Reload page and try again.',
      ...data
    });

    setShow(true);
    return;
  };

  const handleChange = e => {
    e.persist();
    console.log(`freeGuide: ${freeGuide}, target value: ${e.target.value}`);

    setFreeGuide(
      e.target.value
    );
  };

  return (
    <Container>
         <p>
        After 20 wonderful years of serving you, we wanted to share some important news. As of June 23, 2024, we will no longer be processing reports.
      </p>
      <p>
        We deeply appreciate the trust and loyalty you have placed in us over the years. Your support has been integral to our success and growth, and we are truly grateful for the opportunity to have been a part of your journey.
      </p>
<p>
  Thank you for your understanding and continued support.
</p>
<p>
  Warm regards,
</p>
    </Container>
    );
}

export default App;
